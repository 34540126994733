let roleTypeList = [
    {
        value: '1',
        label: '操作员',
    },

    {
        value: '2',
        label: '管理员',
    },
  
]

export default roleTypeList