let API_HOST = '';
if (process.env.NODE_ENV === 'development') {
    API_HOST = "/api"
} else {
    // API_HOST = "http://192.168.0.217:8009"
    API_HOST = "/"
}

let NET_PORT={
    login: API_HOST + 'login',
    accountList: API_HOST + 'user/query',
    accountAdd: API_HOST + 'user/add',
    accountDelete: API_HOST + 'user/del',
    accountModify: API_HOST + 'user/update',
    programsList: API_HOST + 'project/query',
    programsAdd: API_HOST + 'project/add',
    programsDelete: API_HOST + 'project/del',
    programsModify: API_HOST + 'project/update',
    applyPersonList: API_HOST + 'apply/query',
    applyPersonExport: API_HOST + 'excel/download',
    applyPersonImport: API_HOST + 'apply/import',
    templateDownload: API_HOST + 'apply/download/template',
    applyPersonDelete: API_HOST + 'apply/batchDelete',
    applyPersonUpdate: API_HOST + 'apply/update',
}
export default NET_PORT
