import router from '../router'
import { resetRouter } from "../router"

export function exitFn() {
    window.sessionStorage.clear()
    // 重置路由/避免不同账号权限不同路由重复
    resetRouter();
    router.push({
        path: '/login'
    })
}