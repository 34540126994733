import { Base64 } from 'js-base64';

function encryptFn(data){
    return Base64.encode(data)
    // return window.btoa(window.encodeURIComponent(JSON.stringify(data)))
}

function decryptFn(data){
    return Base64.decode(data)
    // return JSON.parse(window.decodeURIComponent(window.atob(data)))
}

export { encryptFn ,decryptFn}