 <template>
  <div class='layout_wrap'>
        <Layout style="height:100%">
            <Sider ref="side" hide-trigger collapsible :collapsed-width="78" v-model="$store.state.isCollapsed">
                <div class="side_menu_wrap">
                    <div class="logo_wrap">
                        <a href="javascript:;">
                            <img src="../assets/img/logo.png" alt="">
                        </a>
                    </div>
                    <Menu v-if="!hideMenu" ref="side_menu" :active-name="defaultPath" :open-names="openNames"  theme="dark" width="auto" :class="menuitemClasses">
                        <template v-for="item in routesData[0].children" >
                            <!-- {{item}} -->
                            <MenuItem :name="'/'+item.path" :to="'/'+item.path" v-if="item.meta.type==='file'&&item.meta.showLeft">
                                    <Icon :type="item.meta.icon" />
                                    <span>{{item.meta.title}}</span>
                            </MenuItem>
                            <Submenu :name="'/'+item.path"  v-if="item.meta.type==='fold'">
                                <template slot="title">
                                    <Icon :type="item.meta.icon" />
                                    <span>{{item.meta.title}}</span>
                                </template>
                                <template v-for="block in item.children">
                                   <MenuItem v-if="block.meta.showLeft" :name="'/'+item.path+'/'+block.path" :to="'/'+item.path+'/'+block.path">{{block.meta.title}}</MenuItem>
                                </template>
                            </Submenu>
                         
                        </template>
                    </Menu>
                    <div v-else>
                        <template v-for="item in routesData[0].children" >
                            <Tooltip transfer placement="right" :content="item.meta.title" v-if="item.meta.type==='file'&&item.meta.showLeft">
                                <router-link :to="'/'+item.path" class="hide_menu_icon">
                                    <Icon :style="defaultPath===('/'+item.path) ? 'color:#67b291' : 'color:#bbbec3'" :type="item.meta.icon" />
                                </router-link>
                            </Tooltip>
                            <Dropdown @on-click="routeDropdownFn" transfer placement="right-start" v-if="item.meta.type==='fold'">
                                <a class="hide_menu_icon">
                                    <Icon :style="('/'+defaultPath.split('/')[1])===item.path ? 'color:#67b291' : 'color:#bbbec3'" :type="item.meta.icon" />
                                </a>
                                <template v-for="block in item.children">
                                    <DropdownMenu slot="list">
                                        <DropdownItem v-if="block.meta.showLeft" :selected="defaultPath===('/'+item.path+'/'+block.path)? true : false" :name="'/'+item.path+'/'+block.path">{{block.meta.title}}</DropdownItem>
                                    </DropdownMenu>
                                </template>
                            </Dropdown>
                        </template>
                    </div>
                </div>
                
            </Sider>
            <Layout>
                <Header :style="{padding: 0,zIndex:2}" class="layout-header-bar">
                    <Icon @click.native="collapsedSider" :class="rotateIcon" :style="{margin: '0 10px 0 20px',verticalAlign:'middle'}" type="md-menu" size="24"></Icon>
                    <div class="bread_crumb_wrap">
                        <Breadcrumb>
                            <BreadcrumbItem v-for="(item,index) in breadCrumbList" :to="item.path" :key="index">{{item.name}}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <div class="user_wrap">
                       <div class="user_box">
                            <Dropdown placement="bottom-end" @on-click="avatarDropdownFn" transfer>
                                <Badge >
                                    <Avatar :src="userAvator" class="avatar"/>
                                </Badge>
                                <Icon :size="18" type="md-arrow-dropdown"></Icon>
                                <DropdownMenu slot="list" >
                                    <div class="user_info">
                                        <h2>{{user}}</h2>
                                    </div>
                                    <DropdownItem name="logout">退出登录</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                       </div>

                        <span v-if="role==='1'" class="role_type_tag primary">{{getRoleTextFn(role)}}</span>
                        <span v-if="role==='2'" class="role_type_tag success">{{getRoleTextFn(role)}}</span>
                    </div>

                </Header>

                <div class="route_nav_wrap">
                    <div class="route_scroll_arrow_left">
                        <Button type="default"  icon="ios-arrow-back" @click="routeScrollLeftFn"></Button>
                    </div>
                    <div class="route_scroll_wrap" ref="routeScrollWrap">
                        <div class="route_scroll_box" ref="routeScrollBox">
                            <Tag :color="item.name===currentRouteName? 'primary':'default'" type="dot" checkable   v-for="(item,index) in openRouteArr" :key="index" :closable="index!==0 ?true :false" @on-change="clickRouteTagFn(item)" @on-close="closeRouteTagFn($event,item.name)">{{item.title}}</Tag>
                        </div>
                    </div>
                    <div class="route_scroll_arrow_right" >
                        <Button type="default"  icon="ios-arrow-forward" @click="routeScrollRightFn"></Button>
                    </div>
                </div>
              
                <Content id="content_wrap" ref="contentWrap">
                        <keep-alive>
                            <router-view @setLoadingFn="loadingBarFn" v-if="$route.meta.keepAlive"></router-view>
                        </keep-alive>
                        <router-view @setLoadingFn="loadingBarFn" v-if="!$route.meta.keepAlive"></router-view>
                    
                        <div id="return_top_wrap">
                            <div class="return_top_box">
                                <Icon type="ios-arrow-up" />
                            </div>
                        </div>
                </Content>
            </Layout>
        </Layout>


        <Modal v-model="statusModal.show" title="" :footer-hide="true" :mask-closable="false" :closable="false" width="360">
            <div style="text-align:center;padding:20px 0">
                <Spin fix v-if="!statusModal.showSuccess&&!statusModal.showError">
                    <Icon type="ios-loading" size=18 class="spin-icon-rotate"></Icon>
                    <div>{{statusModal.text}}</div>
                </Spin>
                <Spin fix v-if="statusModal.showSuccess" style="color:#00ad19">
                    <Icon type="ios-checkmark-circle" size=18 />
                    <div>{{statusModal.successText}}</div>
                </Spin>
                <Spin fix v-if="statusModal.showError" style="color:#f72b2b">
                    <Icon type="ios-close-circle" size=18 />
                    <div>{{statusModal.errorText}}</div>
                </Spin>
            </div>
        </Modal>
        <div v-show="showScaleImg" class="scale_img_wrap"  @click="hideImgFn">
            <img  :src="scaleImgData" alt="">
        </div>
  </div>
</template>

<script>
import Vue from "vue"
import { Layout, Header , Sider , Content ,Submenu,Menu ,MenuItem ,Dropdown ,DropdownMenu, DropdownItem, Badge ,Avatar,Tag,Icon,Tooltip,Breadcrumb,BreadcrumbItem,Modal,Button,Row,Col,Alert,Spin} from 'view-design';
Vue.component('Layout', Layout);
Vue.component('Header', Header);
Vue.component('Sider', Sider);
Vue.component('Content', Content);
Vue.component('Submenu', Submenu);
Vue.component('Menu', Menu);
Vue.component('MenuItem', MenuItem);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Badge', Badge);
Vue.component('Avatar', Avatar);
Vue.component('Tag', Tag);
Vue.component('Icon', Icon);
Vue.component('Tooltip', Tooltip);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('BreadcrumbItem', BreadcrumbItem);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Alert', Alert);
Vue.component('Spin', Spin);

//引入退出登录返回到登录页面方法
import { exitFn } from "../api/logout"

import NET_PORT from "../api/port.js"
import roleTypeList from "../assets/data/role_type.js"
export default {
  name:'layout',
  components:{},
  props:{},
  data(){
    return {
        showScaleImg:false,
        scaleImgData:'',
        showNotice:false,
        noticeNum:2,
        userAvator:require('../assets/img/users.jpg'),
        user:this.$store.state.user,
        role:this.$store.state.role,
        departmentroom:this.$store.state.departmentroom,
        routesData:this.$store.state.routes,
        // defaultPath:'/home',
        // currentRouteName:'home',
        defaultPath:'/training_programs',
        currentRouteName:'training_programs',
        //创建socket
        breadCrumbList:[],
        openRouteArr:[
            // {
            //     name:'home',
            //     path:'/home',
            //     title:'首页'
            // }
        ],
        showAssetsDataCard:false,
        hideMenu:false,
        statusModal:{
            show:false,
            showSuccess:false,
            showError:false,
            text:'正在上传',
            successText:'上传成功！',
            errorText:'上传失败！',
        },

        assetsData:{
            img:[],
            data:[],
        },
    }
  },
  watch:{
        '$route'(after,before){
            let obj={
                name:before.name,
                title:before.meta.title,
                path:before.path,
            }
            this.defaultPath=this.$route.path;
            this.currentRouteName=this.$route.name
            this.setBreadCrumbFn();
            //如果是从各个审批页面返回的那么关掉导航菜单
            // let approveArr=['dispose_approve','important_approve','purchase_approve','receive_approve','storage_approve','return_approve','maintain_approve','adjust_approve'];
            // let isHave=approveArr.find(item=>{return before.name===item})
            //如果是那些返回需要关闭导航菜单的二级页面
            if(before.meta.exitClose){
                this.openRouteArr.forEach((item,index)=>{
                    if(item.name===before.name){
                        this.openRouteArr.splice(index,1)
                    }
                })
            }
            //追加导航菜单
            this.checkRouteFn(this.$route)
        },
        // "$store.state.queryData.department"(){
        //     this.department=this.$store.state.queryData.department;
        // }

        openNames(){
            if(!this.$store.state.isCollapsed){
                this.$nextTick(()=>{
                    this.$refs.side_menu.updateOpened();
                    this.$refs.side_menu.updateActiveName()
                })
            }
        },

        assetsNo(){
            this.showAssetsCardFn(this.$store.state.assetsNo[0])
        }
       
  },
  computed:{
       
      
        assetsNo(){
            return this.$store.state.assetsNo
        },
        openNames(){
            return [
                "/"+this.$route.path.split('/')[1]
            ]
        },
        rotateIcon () {
            return [
                'menu-icon',
                'collapse_icon',
                this.$store.state.isCollapsed ? 'rotate-icon' : ''
            ];
        },
        menuitemClasses () {
            return [
                'menu-item',
                'menu_scroll',
                this.$store.state.isCollapsed ? 'collapsed-menu' : ''
            ]
        }
  },
  methods:{
        loadingBarFn(value){
            switch(value){
                case 'start' :
                    this.$Loading.start();
                break;
                case 'finish' :
                    this.$Loading.finish();
                break;
                case 'error' :
                    this.$Loading.error();
            }
        },
        scaleImgFn(img){
            this.scaleImgData=img;
            this.showScaleImg=true;
        },

        hideImgFn(){
            this.showScaleImg=false;
            this.scaleImgData='';
        },
        successFn(){
            this.statusModal.showSuccess=true;
            setTimeout(()=>{
                this.statusModal.show=false;
                setTimeout(()=>{
                    this.statusModal.showSuccess=false;
                    this.statusModal.showError=false;
                },500)

            },1000)
        },

        errorFn(){
            this.statusModal.showError=true;
            setTimeout(()=>{
                this.statusModal.show=false;
                setTimeout(()=>{
                    this.statusModal.showSuccess=false;
                    this.statusModal.showError=false;
                },500)
            },1000)
        },
       

    
        paramsStrFn(params){
            let paramsStr = '';
            for(let key in params){
                paramsStr += key + '=' + params[key] + '&'
            }
            paramsStr = paramsStr.slice(0,-1);
            return paramsStr
        },
        routeDropdownFn(name){
            this.$router.push(name)
        },
        checkRouteFn(currentRoute){
            let isHave=this.openRouteArr.find((item,index)=>{
                return item.name===currentRoute.name;
            })
            if(!isHave){
                this.openRouteArr.push({
                    name:currentRoute.name,
                    title:currentRoute.meta.title,
                    path:currentRoute.path,
                    query:currentRoute.query,
                })
                window.sessionStorage.setItem('openRouteArr',JSON.stringify(this.openRouteArr))
                this.$nextTick(()=>{
                    let wrapWidth=this.$refs.routeScrollWrap.offsetWidth
                    let boxWidth=this.$refs.routeScrollBox.offsetWidth
                    let currentLeft=this.$refs.routeScrollBox.offsetLeft;
                    if(wrapWidth<boxWidth){
                        this.$refs.routeScrollBox.style.left=(wrapWidth-boxWidth)+"px";
                    }
                })
            }
        },
        closeRouteTagFn(event,name){
            let currentIndex=0;
            this.openRouteArr.forEach((item,index)=>{
                if(item.name===name){
                    this.openRouteArr.splice(index,1)
                    currentIndex=index;
                }
            })
            window.sessionStorage.setItem('openRouteArr',JSON.stringify(this.openRouteArr))
            if(this.currentRouteName===name){
                this.$router.push({
                    path:this.openRouteArr[currentIndex-1].path,
                    query:this.openRouteArr[currentIndex-1].query,
                })
            }
            let wrapWidth=this.$refs.routeScrollWrap.offsetWidth
            let boxWidth=this.$refs.routeScrollBox.offsetWidth
            let currentLeft=this.$refs.routeScrollBox.offsetLeft;
            if(wrapWidth>boxWidth){
                this.$refs.routeScrollBox.style.left=0+"px";
            }
        },
        clickRouteTagFn(item){
            if(this.currentRouteName!==item.name){
                this.$router.push({
                    path:item.path,
                    query:item.query,
                })
            }
        },
        routeScrollLeftFn(){
            let wrapWidth=this.$refs.routeScrollWrap.offsetWidth
            let boxWidth=this.$refs.routeScrollBox.offsetWidth
            let currentLeft=this.$refs.routeScrollBox.offsetLeft;
            if(currentLeft>=-200){
                this.$refs.routeScrollBox.style.left=0+"px";
            }else{
                this.$refs.routeScrollBox.style.left=(currentLeft+200)+"px";
            }
        },
        routeScrollRightFn(){
            let wrapWidth=this.$refs.routeScrollWrap.offsetWidth
            let boxWidth=this.$refs.routeScrollBox.offsetWidth
            let currentLeft=this.$refs.routeScrollBox.offsetLeft;
            if(wrapWidth<boxWidth){
                if((boxWidth+currentLeft)-wrapWidth<=200){
                    this.$refs.routeScrollBox.style.left=(wrapWidth-boxWidth)+"px";
                }else{
                    this.$refs.routeScrollBox.style.left=(currentLeft-200)+"px";
                }
            }
        },
       
        setBreadCrumbFn(){
            if(this.$route.name!=='home'){
                this.breadCrumbList=[
                    // {
                    //     path:'/home',
                    //     name:'首页' 
                    // },
                    {
                        path:'',
                        name:this.$route.meta.title,
                    }
                ];
            }else{
                this.breadCrumbList=[
                    // {
                    //     path:'',
                    //     name:'首页' 
                    // },
                ];
            }
        },
        autoSiderFn(){
            if(window.innerWidth<=1600){
                this.$store.commit('setCollapse',true)
            }else{
                this.$store.commit('setCollapse',false)
            }
            this.$nextTick(()=>{
                    this.hideMenuFn();
            })
        },

        collapsedSider () {
            this.$store.commit('setCollapse',!this.$store.state.isCollapsed)
            //执行折叠菜单的方法
            this.hideMenuFn();
        },

        hideMenuFn(){
            if(this.$store.state.isCollapsed){
                this.hideMenu=true;
            }else{
                setTimeout(()=>{
                        this.hideMenu=false;
                },200)

            }
        },

        avatarDropdownFn(name){
            switch(name){
               
                case 'logout':
                    //清除本地存储并退出页面
                    exitFn();
            }
        },

      
        afterResizeFn(){
            let timer=null;
            clearTimeout(timer)
            timer=setTimeout(()=>{
                this.autoSiderFn();
            },500)
        },

        // getCategoryTextFn(no){
        //    let text='';
        //    assetsCategory.forEach((item,index)=>{
        //        if(item.code===no){
        //            text=item.value
        //        }
        //    })
        //    return text ? text : no;
        // },
        // getAssetsPurposeTextFn(no){
        //     let text='';
        //     baseData.assetsPurposeAll.forEach((item,index)=>{
        //         if(item.code===no){
        //             text=item.value
        //         }
        //     })
        //     return text ? text : no;
        // },
        getCascaderTextFn(type,no){
            let text='';
            baseData[type].forEach((item,index)=>{
                if(item.code===no){
                    text=type==='assetsCategoryAll' ? item.value :item.label;
                }
            })
            return text ? text : no;
        },
        getRoleTextFn(role){
            let text='';
            roleTypeList.forEach((item,index)=>{
                if(item.value===role){
                    text=item.label;
                }
            })
            return text
        },

       
  },
  created(){
        this.roleTypeList=roleTypeList;
        this.autoSiderFn();
        window.addEventListener('resize',this.afterResizeFn,true)
  },
  mounted(){
        this.setBreadCrumbFn();
        this.defaultPath=this.$route.path
        this.currentRouteName=this.$route.name
        if(window.sessionStorage.getItem('openRouteArr')){
            this.openRouteArr=JSON.parse(window.sessionStorage.getItem('openRouteArr'))
        }
        this.checkRouteFn(this.$route)
        this.$nextTick(()=>{
            this.$refs.side_menu.updateActiveName()
        });
  },
   beforeDestroy(){
        window.removeEventListener('resize',this.afterResizeFn,true)
  }
}
</script>
<style lang='scss'>
    @import "../assets/scss/common/default.scss";
</style>
<style lang='scss' scoped>
    @import "./index.scss";
    @import "../assets/scss/common/apply.scss";
</style>