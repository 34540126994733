import store from "../store"
import { getToken } from '../utils/auth'
import { decryptFn } from "./storage_format";

export function getInfo(token) {

    if (getToken() && window.sessionStorage.getItem('username') && window.sessionStorage.getItem('role') && window.sessionStorage.getItem('project') ) {
        store.commit('setRoutes', decryptFn(window.sessionStorage.getItem('role')));
        store.commit('setUser', decryptFn(window.sessionStorage.getItem('username')));
        store.commit('setPersonName', decryptFn(window.sessionStorage.getItem('personName')));
        store.commit('setRole', decryptFn(window.sessionStorage.getItem('role')));
        store.commit('setDepartmentroom', decryptFn(window.sessionStorage.getItem('departmentroom')));
        store.commit('setProject', decryptFn(window.sessionStorage.getItem('project')));
    }

}