<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
html,body,#app{
  width:100%;
  height:100%;
  overflow: hidden;
}

body{
    font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    color:#323846;
}

html,body,h1,h2,h3,h4,h5,p{
    margin:0;
    padding:0;
}

.ivu-table-wrapper{
  border:none!important;
}

.ivu-table td, .ivu-table th{
  border:none!important;
}

.ivu-table:before ,.ivu-table:after{
  display: none!important;
}

/* .ivu-drawer-header{
  padding:0!important;
} */

/* .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
    display: none!important;
} */

// .ivu-menu-dark.ivu-menu-vertical .ivu-menu-opened .ivu-menu-submenu-title{
//         border-radius: 10px!important;
//     }

    

.ivu-menu-vertical.ivu-menu-light:after{
  display: none!important;
}

.ivu-checkbox-group-item{
  margin-bottom:10px!important;
}

.ivu-auto-complete.ivu-select-dropdown{
  max-height:200px!important;
  overflow: auto!important;
}

.ivu-split-horizontal>.ivu-split-trigger-con{
  width:auto!important;
}

.ivu-table-wrapper>.ivu-spin-fix{
  border:none!important;
}

.ivu-table-header{
  border-radius:5px!important;
  
}
/* .ivu-modal-header{
  text-align: center!important;
  
}

.ivu-modal-header-inner{
  font-size:20px!important;
} */

.ivu-modal-header,.ivu-modal-footer{
  border:none!important;
}

.ivu-checkbox{
  margin-right:5px!important;
}

.tree-select {
    .ivu-select-dropdown {
        padding: 0 6px !important;
    }
}

/* .van-uploader__preview-image{
  width:77px!important;
  height:77px!important;
} */

.vue-image-crop-upload .vicp-wrap{
  border-radius:10px!important;
}

.ivu-modal{
  border-radius:6px;
  overflow: hidden;
}


</style>
