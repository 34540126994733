
export function getToken() {
    return window.sessionStorage.getItem('manageToken')
}

export function setToken(token) {
    return window.sessionStorage.setItem('manageToken', token)
}

export function removeToken() {
    return window.sessionStorage.removeItem('manageToken')
}
