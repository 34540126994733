import Vue from "vue";
import Vuex from "vuex"
import Main from "@/layout/index"
import {encryptFn} from "../api/storage_format"

Vue.use(Vuex)

const state = {
    routes: [],
    user: '',
    personName:'',
    role: '',
    departmentroom:'',
    project: '',
    address: '',
    assetsNo:[],
    isCollapsed: false,
}

const mutations = {

    setRoutes(state, role) {
        var allRoutes =
            [
                {
                    path: '/',
                    component: Main,
                    redirect: '/training_programs',
                    meta: {
                        showLeft: true,
                        roles: ['1','2',],
                        
                    },
                    children: [
                        // {
                        //     path: 'home',
                        //     name: 'home',
                        //     meta: {
                        //         title: '首页',
                        //         icon: 'ios-apps',
                        //         showLeft: true,
                        //         roles: ['1', '2', ],
                        //         exitClose:false,
                        //         type:"file",
                        //     },
                        //     component: () => import('@/views/home/index')
                        // },
                        {
                            path: 'training_programs',
                            name: 'training_programs',
                            meta: {
                                title: '培训项目',
                                icon: 'md-cube',
                                showLeft: true,
                                roles: ['1', '2',],
                                exitClose: false,
                                type: "file",
                            },
                            component: () => import('@/views/training_programs/index')
                        },
                        {
                            path: 'apply_detail',
                            name: 'apply_detail',
                            meta: {
                                title: '报名详情',
                                showLeft: false,
                                roles: ['1', '2', ],
                                exitClose: true,
                                type: "file",
                            },
                            component: () => import('@/views/training_programs/apply_detail/index')
                        },
                        {
                            path: 'account_manage',
                            name: 'account_manage',
                            meta: {
                                title: '账号管理',
                                icon: 'md-person',
                                showLeft: true,
                                roles: ['2',],
                                exitClose: false,
                                // keepAlive: true,
                                // needRefresh:true,
                                type: "file",
                            },
                            component: () => import('@/views/account_manage/index')
                        },
                       
                       
                    ]
                },

            ]

        //过滤路由权限
        for( let i=0;i<allRoutes.length;i++){
            allRoutes[i].children = allRoutes[i].children.filter((item, index) => {
                return item.meta.roles.indexOf(role) > -1
            })
        }

        allRoutes = allRoutes.filter((item, index) => {
            return item.meta.roles.indexOf(role) > -1
        })
       
        state.routes = allRoutes;

    },



    setUser(state, newUser) {
        state.user = newUser;
        window.sessionStorage.setItem('username', encryptFn(newUser))
    },
    setPersonName(state, newPersonName) {
        state.personName = newPersonName;
        window.sessionStorage.setItem('personName', encryptFn(newPersonName))
    },

    setRole(state, newRole) {
        state.role = newRole;
        window.sessionStorage.setItem('role', encryptFn(newRole))
    },

    setProject(state, newProject) {
        state.project = newProject;
        window.sessionStorage.setItem('project', encryptFn(newProject))
    },
    setDepartmentroom(state, newDepartmentroom) {
        state.departmentroom = newDepartmentroom;
        window.sessionStorage.setItem('departmentroom', encryptFn(newDepartmentroom))
    },

    setCollapse(state, newCollapse) {
        state.isCollapsed = newCollapse;
    },

    setAssetsNo(state, newAssetsNo) {
        state.assetsNo = [newAssetsNo, new Date()];
    },
  
}

const store = new Vuex.Store({
    state,
    mutations,
})



export default store